.key-container {
    margin: 0 10px 0 10px;
}

.key-list-heading {
    padding: 20px 20px 0 20px;
}

.nav-buttons {
    margin: 20px;
}

.nav-buttons > input[type=button] {
    float: right;
    margin: 0 0 20px 10px;
}
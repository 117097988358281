.navbar {
    padding: 20px 30px;
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.navbar .navbar-title {
    font-size: 22px;
    font-weight: 400;
}

.navbar .navbar-brand {
    flex-grow: 1;
}

.navbar a {
    text-decoration: none;
}

.navbar a.active {
    color: var(--disabled);
}

.navbar-brand a {
    color: var(--text);
}

.navbar-brand a:hover {
    color: var(--light);
}

.navbar .navbar-links {
    flex-grow: 0;
    display: flex;
    align-items: center;
}

.navbar-links a, .navbar-links button {
    font-weight: 400;
    color: var(--highlight);
    margin-left: 20px;
}
.login {
    padding: 10px 20px;
    max-width: 450px;
    margin: 20px auto;
}

.login > h1 {
    text-align: center;
}

.separator {
    text-align: center;
    color: var(--light);
}
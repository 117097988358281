.about {
    padding: 0 20px;
}

.about p {
    font-size: 20px;
    color: var(--light);
}

.about span.highlight {
    color: #fff;
}

.about span.italic {
    font-style: italic;
    margin-right: 3px;
}

.about .diagram {
    text-align: center;
}

.diagram span {
    font-size: 24px;
}

.diagram.horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.diagram .block {
    border: 3px solid var(--highlight);
    padding: 10px 15px;
    margin: 10px;
    display: inline-block;
    box-shadow: 0 2px 8px rgba(0,0,0,0.5);
}

.diagram .block-container {
    display: inline-block;
    position: relative;
}

.diagram .block-description {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 100%;
    text-align: left;
    background: var(--shadow);
    padding: 10px 20px;
    transition: opacity .3s ease;
    opacity: 0;
    box-shadow: 0 3px 8px var(--shadow);
    word-break: normal;
}

.diagram .block-container:hover .block-description {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.diagram .block-description p {
    font-size: 16px;
    margin: 5px 0;
    white-space: nowrap;
}

.diagram .block.random {
    border-color: #e15722;
}

.diagram .block.function {
    border-color: #fff;
}

.diagram .block.wk {
    border-color: #ae1dc2;
}

.arrow-line {
    stroke: #fff;
    stroke-width: 3;
}

.arrow-head {
    fill: #fff;
}

.diagram svg {
    margin: 10px 0;
}

.diagram svg.right {
    margin: 0 10px;
}

.img-container {
    max-width: 500px;
    margin: auto;
}

.img-container img {
    max-width: 100%;
}
.form-group {
    margin: 20px 0;
}

.form-group.collapse {
    margin: 0;
}

.form-group .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-group .action input[type=password], .form-group .action input[type=text] {
    width: auto;
    flex-grow: 2;
    margin-right: 10px;
}

.form-group .action input[type=submit], .form-group .action input[type=button] {
    flex-grow: 1;
    max-width: 100px;
}

.form-label {
    display: block;
    margin: 10px 5px;
    font-size: 15px;
    font-weight: 400;
    color: var(--light);
}
body {
  padding: 0;
  margin: 0;
  background: var(--dark);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  min-width: 300px;
}

body, input, textarea {
  font-family: "Barlow Condensed", sans-serif;
}

h1, h2, h3, h4 {
  font-weight: 400;
}

.container {
  max-width: 750px;
  margin: auto;
  padding: 20px 10px;
}

.container.small {
  max-width: 500px;
  padding: 20px 30px;
}

.btn-group {
    display: flex;
    justify-content: space-between;
}

.btn-group .btn {
    margin: auto 8px;
}

.btn-group .btn:first-child {
    margin-left: 0;
}

.btn-group .btn:last-child {
    margin-right: 0;
}

.btn {
  background-color: var(--light);
  border: solid var(--highlight-dark);
  border-width: 0 0 4px 0;
  color: var(--dark);
  padding: 12px;
  font-size: 18px;
  border-radius: 4px;
  display: block;
  width: 100%;
  transition: all .4s ease;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.btn-outline {
  border: 2px solid var(--highlight);
  border-radius: 4px;
  color: var(--text);
  font-size: 16px;
  display: inline-block;
  padding: 12px 20px;
  background-color: initial;
  transition: all .4s ease;
}

.btn-outline.danger {
  border-color: #e15722;
  margin-left: 10px;
}

.btn-block {
  display: inline-block;
  width: auto;
}

.btn-outline:disabled {
  border-color: var(--primary);
}

.btn-outline:hover {
  background-color: rgba(255,255,255,0.1);
}

.btn:active {
  transform: scale(0.98);
}

.btn.btn-small {
  display: inline-block;
  max-width: 100px;
}

.btn:disabled {
  background-color: var(--disabled);
  border-color: var(--light-secondary);
  color: var(--text-secondary);
  transition: all .4s ease
}

.btn-primary:not([disabled]) {
  background-color: var(--highlight);
  color: var(--text-secondary);
}

.btn-primary:hover:not([disabled]) {
  background-color: var(--highlight-dark);
  border-color: var(--highlight-darker);
}

.btn-danger:not([disabled]) {
  background-color: #e15722;
  color: var(--text-secondary);
  border-color: #a54920;
}

.btn-danger:hover:not([disabled]) {
  background-color: #c34e22;
  border-color: #87411a
}

.btn-secondary:not([disabled]) {
  background-color: #57af1c;
  color: var(--text-secondary);
  border-color: #44871a;
}

.btn-secondary:hover:not([disabled]) {
  background-color: #549b18;
  border-color: #417d13;
}

.btn-logout {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 15px;
  padding: 5px 15px 6px 15px;
  box-shadow: none;
  border: none;
  background: var(--disabled);
}

.popup-form {
  background-color: var(--light-secondary);
  border-radius: 5px;
  border: 3px solid var(--dark);
  transform: translateX(-50%);
  position: absolute;
  top: 80px;
  left: 50%;
  z-index: 100;
  width: 70%;
  max-width: 300px;
  padding: 20px 40px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.4);
  transition: opacity .2s;
  opacity: 1;
}

.popup-form.hidden {
  opacity: 0;
  pointer-events: none;
}

.close-btn {
  width: 40px;
  background: none;
  border: none;
  color: var(--light);
  font-size: 18px;
  padding: 10px;
  margin: 0;
  border-radius: 3px;
  position: absolute;
  right: 5px;
  top: 5px;
  text-shadow: 0 2px 2px var(--dark);
  transition: background-color .2s;
}

.close-btn:hover {
  background: var(--primary);
}

table {
  width: 100%;
  padding: 10px;
  background-color: var(--primary);
  border-radius: 5px;
  border-collapse: collapse;
  margin: 20px auto;
  border-bottom: 4px solid var(--secondary);
}

th, td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid var(--secondary);
}

td > p {
  margin: 5px 0;
}

tbody.loading {
  opacity: 0.9;
}

tbody > tr:hover {
  background-color: var(--secondary);
}

.navlink {
  text-decoration: none;
  color: var(--highlight);
  font-weight: 400;
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.float-right {
  float: right;
}
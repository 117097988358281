.slider {
    text-align: center;
    width: 100%;
}

.slider input {
    -webkit-appearance: none;
    width: 80%;
    height: 2px;
    position: relative;
    top: 0;
    background: var(--disabled);
    margin: 20px auto 0 auto;
    outline: none;
    transition: .3s ease;
}

.slider input:focus {
    outline: none;
    border: none;
    background: var(--highlight);
}

.slider input::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: .3s ease;
    width: 5.5%;
    height: 15px;
    position: relative;
    top: 6px;
    background: var(--disabled);
    border: none;
    cursor: pointer;
}

.slider input:focus::-webkit-slider-thumb {
    background: var(--highlight);
    box-shadow: 0 0 8px var(--highlight);
}

.slider input::-moz-range-thumb {
    transition: .3s ease;
    position: relative;
    top: 6px;
    width: 5.5%;
    height: 15px;
    background: var(--disabled);
    cursor: pointer;
}
@font-face {
  font-family: "Barlow Condensed";
  src: url("/fonts/BarlowCondensed-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #3f3f3f;
  --secondary: #131313;
  --highlight: #27a5e1;
  --highlight-dark: #2c84cd;
  --highlight-darker: #2770af;
  --dark: #222222;
  --light: #d4d4d4;
  --light-secondary: #2a2a2a;
  --shadow: #111111;
  --text: #ffffff;
  --text-secondary: #fff;
  --disabled: #4b4b4b;
}

.client {
    display: flex;
    justify-content: space-between;
    background-color: #373737;
    margin: 10px auto;
    transition: .3s ease;
    border-radius: 5px;
    padding: 20px;
    max-width: 500px;
}

.client-heading {

}

.client-heading h3 {
    margin: 0 0 5px 0;
    font-size: 22px;
}

.client-heading p {
    margin: 0;
    color: var(--light)
}

.client:hover {
    background-color: var(--disabled);
}
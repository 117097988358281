.key {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #373737;
    margin: 10px;
    transition: .3s ease;
    border-radius: 5px;
    padding: 15px;
}

.key:hover {
    background-color: var(--disabled);
    transform: translateY(-2px);
    box-shadow: 0 3px 8px #181818;
}

.key .key-heading {
    transition: background-color .3s ease;
    flex-grow: 2;
}

.key .key-heading h4 {
    margin: 0;
    font-size: 22px;
}

.key .key-heading a {
    text-decoration: none;
    color: var(--highlight)
}

.key .key-body {
    font-size: 18px;
    flex-grow: 1;
    text-align: right;
    max-width: 60%;
}

.key .key-body p {
    margin: 0;
    color: var(--light);
    word-wrap: break-word;
}

.key .key-body span {
    display: inline;
    word-wrap: break-word;
    color: #111111;
    background-color: #111111;
    border-bottom: 2px solid #1f1f1f;
    transition: .2s ease-in-out;
}

.key:hover .key-body span {
    color: #fff;
    background-color: initial;
    border-color: rgba(0,0,0,0);
}
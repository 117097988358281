.authorize-card {
    margin: 50px 0;
    border: 2px solid var(--primary);
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
}

.authorize-card > div {
    padding: 20px;
}

.authorize-card > .header {
    font-size: 18px;
    background-color: var(--primary)
}

.authorize-card .code {
    font-size: 18px;
    text-transform: uppercase;
    color: var(--highlight);
    margin-left: 5px;
}

.authorize-card > .body > p {
    margin: 5px 0;
}
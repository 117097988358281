.form {
    width: 100%;
    margin: auto;
}

.form input[type=text], .form input[type=password],
.form input[type=email], .form input[type=tel],
.form input[type=number], .form textarea {
    padding: 12px;
    font-size: 18px;
    background-color: var(--primary);
    color: var(--text);
    border: 2px solid var(--primary);
    border-radius: 4px;
    outline: none;
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    transition: border-color .3s ease;
}

.form input[type=password].error, .form input[type=text].error {
    border-color: #e13814;
}

.form input:focus, .form textarea:focus {
    border: 2px solid var(--highlight);
}

.form input[type=text].uppercase {
    text-transform: uppercase;
}

.form-actions {
    margin-top: 30px;
}

.form .link-button {
    text-decoration: none;
}